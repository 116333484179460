<template>
<div>
  <NavBar
  title="基本信息"
  left-arrow
  @click-left="$router.go(-1)"
/>
<vfrom @submit="onSubmit"   >
  <vfield
    v-model="user.nickName"
    name="nickName"
    label="姓名"
    required
    placeholder="请输入姓名"
    :rules="[{ required: true, message: '姓名不能为空' }]"
  />
  <vfield
    v-model="user.idCard"
    name="idCard"
    required
    label="身份证号"
    placeholder="请输入身份证号"
    :rules="[{ required: true, message: '身份证号不能为空' }]"
  />
  <vfield name="gender" label="性别" required >
        <template #input >
          <RadioGroup v-model="user.gender" direction="horizontal">
            <Radio name="0" >女</Radio>
            <Radio name="1" >男</Radio>
          </RadioGroup>
        </template>
  </vfield>
   <vfield 
          name="centerId"
          :value="user.centerId"
          v-show="false"
      />
    <vfield 
         readonly
               clickable
               name="centerName"
               :value="centerName"
               placeholder="请选择"
               label="机构"
               required
               @click="showPicker = true"
      />
    <Popup v-model="showPicker" position="bottom">
              <Picker
               show-toolbar
               :columns="opts"
               @confirm="onConfirm"
               @cancel="showPicker = false"
           />
    </Popup>
   <vfield
    v-model="user.phone"
    name="phone"
    required
    label="手机号"
    placeholder="请输入手机号"
    :rules="[{ required: true, message: '手机号不能为空' }]"
  />
   <vfield v-model="code" name="code"
                     center
                     required
                     clearable
                     label="验证码"
                     placeholder="请输入验证码"
                     :border="false"
                     use-button-slot
                     :rules="[{ validator: valiVerifyCode, message: '验证出错'}]"
                     >
    <Button slot="button"
                        size="small"
                        type="info" native-type="button"
                        @click="gainCode">{{time}}</Button>
   </vfield>
  <div style="margin: 16px;">
    <Button round block type="info" :disabled="substatus" native-type="submit" >提交</Button>
  </div>
</vfrom>
<RLFooter/>
</div>

</template>

<script>
import {  Form as vfrom,Field as vfield,Button,Radio,RadioGroup,Popup ,Picker } from 'vant';
import {saveBaseInfo,getBaseInfo} from '@/api/patient/baseinfo';
import {reSendVerifyCode,verifyCode}  from '@/api/upload';
import {qCenters} from '@/api/healthcenter';

export default {
  name: 'baseinfo',
  components: {
      vfrom,vfield,Button,Radio,RadioGroup,Popup ,Picker
  },
  data(){
    return {
      user:{
        nickName:'',
        idCard:'',
        phone:'',
        gender:'1',
        centerId:undefined,
      },
       time: '获取验证码',
       substatus:true,
       hasSendCode:false,
       code:'',
       isClick:true,
       showPicker:false,
       centerName:undefined,
       opts:[],
       centerMap:{}
    };
  },
  methods:{
     onConfirm(value){
       this.user.centerId = value.val;
       this.centerName=value.text;
       this.showPicker = false;
     },
     onSubmit(values) {
       if(values.centerName){
          delete values.centerName;
       }
      saveBaseInfo(values).then((response) => {
          if(response.code==200 ){
               this.$router.push({path:'/',query:{}});
               this.$toast.success('操作成功');
          }else{
              this.$router.push({path:'/',query:{}});
              this.$toast.fail('操作失败请稍候重试');
          }
    });
    },
    initBaseInfo(){
        getBaseInfo({}).then((response) => {
          if(response.code==200 ){
               this.user.nickName=response.data.nickName;
               this.user.idCard=response.data.idCard;
               this.user.phone=response.data.phone;
               this.user.gender=response.data.gender+'';
               this.user.centerId=response.data.centerId;
               if(this.user.centerId){
                 this.centerName=this.centerMap["id_"+this.user.centerId].text;
               }
          }
    });
    },
    valiVerifyCode(){
        return new Promise((resolve) => {
          if(!this.hasSendCode){
               resolve(true);
          }else{
          this.Toast.loading('验证中...');
              if(this.code){
                  verifyCode({phone:this.user.phone,code:this.code}).then((response) => {
                      this.Toast.clear();
                      this.hasSendCode=false;
                       if(200==response.code){
                         this.substatus=false;
                      }
                      resolve(response.data);
                  })
              }
          }        
      });
    },
     // 获取验证码
    async gainCode() {
      if (this.isClick) {
        if (/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.user.phone)) {
          await reSendVerifyCode({ phone: this.user.phone })  // 发送验证码接口
          this.hasSendCode=true;
          this.isClick = false
          let s = 60
          this.time = s + 's'
          let interval = setInterval(() => {
            s--
            this.time = s + 's'
            if (s < 0) {
              this.time = '重新获取'
              this.isClick = true
              clearInterval(interval)
            }
          }, 1000)
        } else {
          this.Toast.fail('请输入正确的手机号码')
        }
      }else{
        this.Toast.fail('请稍候在获取')
      }
    },
  },
   created() {
     qCenters({}).then((response)=>{
        if(response.code==200 ){
             this.opts=response.data;
             this.centerMap=this.builtMap(this.opts,'val',"id_");
         }
        this.initBaseInfo();
      });
      
  },
}
</script>

<style scoped>


</style>
